import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle, siteSubtitle }) => (
  <header
    style={{
      // background: `rebeccapurple`,
      padding: `1.75rem`,
      flexGrow: 0,
    }}
  >
    <h1>
      <Link
        to="/"
        style={
          {
            // color: `white`,
            // whiteSpace: `nowrap`,
          }
        }
      >
        {siteTitle}
      </Link>
    </h1>

    <h2>{siteSubtitle}</h2>

    <h3
      style={
        {
          // whiteSpace: `nowrap`,
        }
      }
    >
      Binzstrasse 12
      <br /> 8045 Zürich
      <br /> tel +41 44 450 77 81
      <br />
      <a href="mailto:mail@kupe.ch">mail@kupe.ch</a>
    </h3>

    <p>Webseite in Überarbeitung</p>

    <p>
      <a
        href="https://www.swiss-architects.com/de/kuhlbrodt-and-peters-architekten-zurich"
        target="_blank"
        rel="noreferrer"
      >
        &gt; www.swiss-architects.com
      </a>
    </p>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
