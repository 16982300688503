/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          subtitle
        }
      }
    }
  `)

  return (
    <div
      className="wrapper"
      style={
        {
          // margin: `0 auto`,
          // maxWidth: 960,
          // display: `flex`,
        }
      }
    >
      <Header
        siteTitle={data.site.siteMetadata?.title || `Title`}
        siteSubtitle={data.site.siteMetadata?.subtitle || `Subtitle`}
      />
      <main
        style={{
          // margin: `0 auto`,
          // maxWidth: 960,
          // background: `green`,
          padding: `1.75rem`,
          flexGrow: 1,
          maxHeight: `100vh`,
        }}
      >
        {children}
      </main>
      <footer
        style={{
          display: `none`,
        }}
      >
        © {new Date().getFullYear()} {` `}
        {data.site.siteMetadata?.title}
      </footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
